import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {SelectOption} from '../../model/form/select-option.model';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {ProgramDTO} from '../../model/dto/program.dto';
import {map} from 'rxjs/operators';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class ProgramWebservice extends AbstractWebservice {
  entity = `programs`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getPrograms(): Observable<ProgramDTO[]> {
    return this.get(`${this.root}`).pipe(map((value) => value as ProgramDTO[]));
  }

  getAllPrograms(): Observable<ProgramDTO[]> {
    return this.get(`${this.root}/all`).pipe(map((value) => value as ProgramDTO[]));
  }

  getProgramsWithActivityTypes() {
    return this.get(`${this.root}/withActivityTypes`);
  }

  getProgramsActives() {
    return this.get(`${this.root}/actives`);
  }

  getEvents(id: number) {
    return this.get(`${environment.envApiUrl}/Events/program/${id}`);
  }
}
